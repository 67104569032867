import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './index.scss';

const styles = theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
});

class SelectField extends React.Component {

    render() {
        const { classes } = this.props;
        return (
            <div>
                <FormControl className={classes.formControl+' contact-form selectField'}>
                    <InputLabel htmlFor="age-simple">{this.props.label}</InputLabel>
                    <Select
                        required
                        value={this.props.val}
                        onChange={this.props.valChange}
                        inputProps={{
                            name: this.props.name,
                            id: this.props.fieldID,
                        }}
                    >
                        {this.props.values.map((e,i) => {
                            return(
                                <MenuItem key={i} value={this.props.values[i].value}>{this.props.values[i].label}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </div>
        )
    }
}

SelectField.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectField);